import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Loader from './Components/Loader';
import ScrollToTop from './Components/ScrollToTop';
// Home page
import Home from './Pages/Home';
// Other pages
const Download = React.lazy(() => import('./Pages/Download'));
const Error = React.lazy(() => import('./Pages/Error'));

function AppRoutes() {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    
    return (
        <Router basename="/">
            <Suspense fallback={
                <div className='appie-loader active'>
                    <Loader />
                </div>
            }>
                <ScrollToTop>
                    <Routes>
                        <Route index={true} element={<Home />} />
                        <Route path="/download" element={<Download />} />
                        <Route path="*" element={<Error />} />
                    </Routes>
                </ScrollToTop>
            </Suspense>
        </Router>
    );
}

export default AppRoutes;