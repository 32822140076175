import React from 'react';
import { Link } from 'react-router-dom';
import ExternalLinks from '../Lib/ExternalLinks';

function Navigation() {
    return (
        <ul>
            <li>
                <Link to="/">Home</Link>
            </li>
            <li>
                <a href="#">
                    Demo <i className="fal fa-angle-down" />
                </a>
                <ul className="sub-menu">
                    <li>
                        <a 
                            href={ExternalLinks.demo_online} 
                            title='Demo now'
                            target='_blank'>Online limited</a>
                    </li>
                    <li>
                        <a 
                            href={ExternalLinks.demo_booking} 
                            title='Schedule a free demo session with one of our agents'
                            target='_blank'>Book a full demo</a>
                    </li>
                </ul>
            </li>
            <li>
                <a href={ExternalLinks.documentation_root} target='_blank'>
                    Docs <i className="fal fa-link" />
                </a>
            </li>
        </ul>
    );
}

export default Navigation;
