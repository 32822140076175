import { useState } from 'react';

export type CardAction = {
    set: React.Dispatch<React.SetStateAction<boolean>>
    toggle: (e: any) => void
}

function useToggle(initValue = false) {
    const [value, setValue] = useState(initValue);
    
    const action: CardAction = {
        set: setValue,
        toggle: (e: any) => {
            e.preventDefault();
            setValue((flag) => !flag);
        },
    }
    return [value, action];
}

export default useToggle;