import React, { useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import videoSlideOne from '../assets/images/video-slide-1.png';
import videoSlideTwo from '../assets/images/video-slide-2.png';
import videoSlideThree from '../assets/images/video-slide-3.png';
import videoCover from '../assets/images/video-cover.PNG';
import PopupVideo from './PopupVideo';
import ExternalLinks from '../Lib/ExternalLinks';

function VideoPlayer({ className }) {
    const [showVideo, setshowVideoValue] = useState(false);
    const handleVideoShow = (e) => {
        e.preventDefault();
        setshowVideoValue(!showVideo);
    };
    const sliderRef = useRef();
    const settings = {
        autoplay: true,
        arrows: false,
        dots: false,
    };
    const sliderNext = () => {
        sliderRef.current.slickNext();
    };
    const sliderPrev = () => {
        sliderRef.current.slickPrev();
    };
    return (
        <>
            {showVideo && (
                <PopupVideo
                    handler={(e) => handleVideoShow(e)}
                    videoSrc={ExternalLinks.video_xpharma_intro}
                />
            )}
            <section className={`appie-video-player-area pb-100 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="appie-video-player-item">
                                <div className="thumb">
                                    <img src={videoCover} height={279} alt="" />
                                    <div className="video-popup-overlay">
                                        <div className="video-popup">
                                            <a
                                                onClick={(e) => handleVideoShow(e)}
                                                role="button"
                                                href="#"
                                                className="appie-video-popup"
                                            >
                                                <i className="fas fa-play" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <h3 className="title">
                                        Watch our three-minute video to learn how.
                                    </h3>
                                    <p>
                                        With its attractive design, XPharma has been developed 
                                        to be intuitive and easy to use. As a result, it's quick 
                                        and easy to get up and running.
                                    </p>
                                    {/* <a className="main-btn" href="#">
                                        Get Started
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div
                                className="appie-video-player-slider"
                                style={{ position: 'relative' }}
                            >
                                <span
                                    onClick={sliderPrev}
                                    className="prev slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-left" />
                                </span>
                                <Slider ref={sliderRef} {...settings}>
                                    <div className="item">
                                        <img src={videoSlideOne} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideTwo} alt="" />
                                    </div>
                                    <div className="item">
                                        <img src={videoSlideThree} alt="" />
                                    </div>
                                </Slider>
                                <span
                                    onClick={sliderNext}
                                    className="next slick-arrow"
                                    style={{ display: 'block' }}
                                >
                                    <i className="fal fa-arrow-right" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default VideoPlayer;
