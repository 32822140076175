import React from 'react';
import IconSettings from '../assets/images/icon/settings.png';
import IconBulb from '../assets/images/icon/bulb.png';
import IconShield from '../assets/images/icon/shield.png';
import IconAssistant from '../assets/images/icon/assistant.png';

function ChoosingUs({ style }) {
    return (
        <>
            <section className="appie-counter-area pt-90 pb-190" id="counter" style={style}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title">
                                <h3 className="appie-title">Why choose XPharma?</h3>
                                <p>
                                    Take full control of your hospital pharmacy with a single app.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="elevate appie-single-service text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={IconBulb} alt="" />
                                    <span>1</span>
                                </div>
                                <h4 className="appie-title">Free and intuitive</h4>
                                <p>Get it and use it free. The app is easy to use, yet performant.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="elevate appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <img src={IconShield} alt="" />
                                    <span>2</span>
                                </div>
                                <h4 className="appie-title">Robust and secure</h4>
                                <p>The app is powerfull and robust, with a key attention on the security.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="elevate appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={IconSettings} alt="" />
                                    <span>3</span>
                                </div>
                                <h4 className="appie-title">Highly customisable</h4>
                                <p>The app can be customised on demand as you need. Contact us.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div
                                className="elevate appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="800ms"
                            >
                                <div className="icon">
                                    <img src={IconAssistant} alt="" />
                                    <span>4</span>
                                </div>
                                <h4 className="appie-title">Customer support</h4>
                                <p>We provide real-time, efficient, and friendly support to our clients.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ChoosingUs;
