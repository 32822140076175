import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/xpharma-logo.png';
import ExternalLinks from '../Lib/ExternalLinks';

function Footer({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Disclaimer</h4>
                                <p>
                                    Although free, XPharma is the property of Kamvusoft. 
                                    Therefore, you are free to use it, but not to sell it.
                                </p>
                                <div className="logo pt-4">
                                    <Link to='/'>
                                        <img src={logo} alt="XPharma" width={'50%'}/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Useful Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/download">Download</Link>
                                    </li>
                                    <li>
                                        <a href={`${ExternalLinks.documentation_root}/getting-started/installation`} target='_blank'>
                                            Installation
                                        </a>
                                    </li>
                                    <li>
                                        <a href={ExternalLinks.documentation_root} target='_blank'>Documentation</a>
                                    </li>
                                    <li>
                                        <a href={`${ExternalLinks.kamvusoft_home}/about`}>About Kamvusoft</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:contact@kamvusoft.com">
                                            <i className="fal fa-envelope"></i> contact@kamvusoft.com
                                        </a>
                                    </li>
                                    <li>
                                        <Link href='#'>
                                            <i className="fal fa-map-marker-alt"></i> Carrefour des immeubles, Kotto, Douala
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="copyright-text">
                                    <p>Copyright © 2023 <a href={ExternalLinks.kamvusoft_home}>Kamvusoft</a>. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Footer;
