const ExternalLinks = {
    documentation_root: "https://kamvusoft.gitbook.io/xpharma",
    support_page: "https://donorbox.org/kamvusoft?default_interval=o&amount=10",
    demo_booking: "https://xpharma.youcanbook.me",
    kamvusoft_home: "http://www.kamvusoft.com",
    demo_online: "https://demo-xpharma.kamvusoft.com",
    video_xpharma_intro: "https://www.youtube.com/embed/B3bMJ2l-frw?autoplay=1",
    socialmedias: {
        youtube: "http://www.youtube.com/@Kamvusoft",
        twitter: "https://twitter.com/kamvusoft",
        linkedIn: "https://www.linkedin.com/company/102499253/",
        facebook: "https://www.facebook.com/profile.php?id=61558502415375"
    }
}

export default ExternalLinks;