import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ExternalLinks from '../Lib/ExternalLinks';

function Pricing() {
    const [toggleSwitch, setSwitchValue] = useState(true);
    const handleSwitch = (e) => {
        e.preventDefault();
        setSwitchValue(!toggleSwitch);
    };
    return (
        <>
            <section className="appie-pricing-2-area pb-100 pt-90">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Simple pricing for Everyone</h3>
                                <p>
                                    The training costs $85 per person and is not included in the rates below.
                                </p>
                                <div className="appie-pricing-tab-btn">
                                    <ul
                                        className="nav nav-pills mb-3"
                                        id="pills-tab"
                                        role="tablist"
                                    >
                                        <li className="nav-item" role="presentation">
                                            <a
                                                onClick={(e) => handleSwitch(e)}
                                                className={`nav-link  ${
                                                    toggleSwitch ? 'active' : ''
                                                }`}
                                                href="#"
                                                type="button"
                                                role="tab"
                                            >
                                                Monthly
                                            </a>
                                        </li>
                                        <li
                                            className={`nav-item ${toggleSwitch ? 'on' : 'off'}`}
                                            role="presentation"
                                        >
                                            <a
                                                onClick={(e) => handleSwitch(e)}
                                                className={`nav-link  ${
                                                    toggleSwitch === false ? 'active' : ''
                                                }`}
                                                href="#"
                                                type="button"
                                                role="tab"
                                            >
                                                Yearly
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="tab-content" id="pills-tabContent">
                                <div
                                    className={`tab-pane fade ${toggleSwitch ? 'active show' : ''}`}
                                    id="pills-home"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        wow
                        animated
                        fadeInLeft
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Fresh</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>00</span>
                                                        <p>/month</p>
                                                    </div>
                                                    <p>Get your app for free</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Licence
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Life
                                                            time update
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <Link to="/download" className="main-btn">
                                                            Download now
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        active
                        wow
                        animated
                        fadeInUp
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Sweet</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>76</span>
                                                        <p>/month</p>
                                                    </div>
                                                    <p>Billed $760 annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Licence
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Life
                                                            time update
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Email
                                                            support
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Skype
                                                            support (1h per week)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Whatsapp
                                                            support (1h per week)
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href={`${ExternalLinks.kamvusoft_home}/contact`}>
                                                            Contact us
                                                        </a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Most Popular</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        item-2
                        wow
                        animated
                        fadeInRight
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Juicy</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>170</span>
                                                        <p>/month</p>
                                                    </div>
                                                    <p>Billed $1700 annually.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Licence
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Life
                                                            time update
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Email
                                                            support
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Skype
                                                            support (24/7)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Whatsapp
                                                            support (24/7)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Anydesk/Teamviewer
                                                            support (24/7)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Free
                                                            installation
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href={`${ExternalLinks.kamvusoft_home}/contact`}>
                                                            Contact us
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`tab-pane fade ${
                                        toggleSwitch === false ? 'active show' : ''
                                    }`}
                                    id="pills-profile"
                                    role="tabpanel"
                                    aria-labelledby="pills-profile-tab"
                                >
                                    <div className="row justify-content-center">
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        animated
                        fadeInLeft
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Fresh</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>00</span>
                                                        <p>/Yearly</p>
                                                    </div>
                                                    <p>Get your app for free</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Licence
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Life
                                                            time update
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <Link to="/download" className="main-btn">
                                                            Download now
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        active
                        animated
                        fadeInUp
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Sweet</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>760</span>
                                                        <p>/Yearly</p>
                                                    </div>
                                                    <p>Save $152 by paying per year.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Licence
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Life
                                                            time update
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Email
                                                            support
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Skype
                                                            support (1h per week)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Whatsapp
                                                            support (1h per week)
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href={`${ExternalLinks.kamvusoft_home}/contact`}>
                                                            Contact us
                                                        </a>
                                                    </div>
                                                    <div className="pricing-rebon">
                                                        <span>Most Popular</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6">
                                            <div
                                                className="
                        pricing-one__single pricing-one__single_2
                        item-2
                        animated
                        fadeInRight
                      "
                                            >
                                                <div className="pricig-heading">
                                                    <h6>Juicy</h6>
                                                    <div className="price-range">
                                                        <sup>$</sup> <span>1700</span>
                                                        <p>/Yearly</p>
                                                    </div>
                                                    <p>Save $340 by paying per year.</p>
                                                </div>
                                                <div className="pricig-body">
                                                    <ul>
                                                        <li>
                                                            <i className="fal fa-check" /> Licence
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Life
                                                            time update
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Email
                                                            support
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Skype
                                                            support (24/7)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Whatsapp
                                                            support (24/7)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Anydesk/Teamviewer
                                                            support (24/7)
                                                        </li>
                                                        <li>
                                                            <i className="fal fa-check" /> Free
                                                            installation
                                                        </li>
                                                    </ul>
                                                    <div className="pricing-btn mt-35">
                                                        <a className="main-btn" href={`${ExternalLinks.kamvusoft_home}/contact`}>
                                                            Contact us
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Pricing;
