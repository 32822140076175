import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/xpharma-logo.png';
import ExternalLinks from '../Lib/ExternalLinks';

function Drawer({ drawer, action }) {
    const [itemSize, setSize] = useState('0px');
    const [item, setItem] = useState('');
    const handler = (e, value) => {
        // e.preventDefault();
        if(item === value) {
            setItem('');
        }
        else {
            setItem(value);
            const getItems = document.querySelectorAll(`#${value} li`).length;
            if (getItems > 0) {
                setSize(`${43 * getItems}px`);
            }
        }
    };
    return (
        <>
            <div
                onClick={(e) => action(e)}
                className={`off_canvars_overlay ${drawer ? 'active' : ''}`}
            ></div>
            <div className="offcanvas_menu">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div
                                className={`offcanvas_menu_wrapper ${
                                    drawer ? 'active' : ''
                                }`}
                            >
                                <div className="canvas_close">
                                    <a href="#" onClick={(e) => action(e)}>
                                        <i className="fa fa-times"></i>
                                    </a>
                                </div>
                                <div className="offcanvas-brand text-center mb-40">
                                    <img src={logo} alt="Kamvusoft" className='mw-200'/>
                                </div>
                                <div id="menu" className="text-left ">
                                    <ul className="offcanvas_main_menu">
                                        <li
                                            onClick={(e) => handler(e, 'home')}
                                            id="home"
                                            className="active"
                                        >
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'demo')}
                                            id="demo"
                                            className="menu-item-has-children active"
                                        >
                                            <span className="menu-expand">
                                                <i className={`fa ${item === 'demo'? 'fa-angle-up':'fa-angle-down'}`}></i>
                                            </span>
                                            <a href="#">Demo</a>
                                            <ul
                                                className="sub-menu"
                                                style={{
                                                    height:
                                                        item === 'demo' ? itemSize : '0px',
                                                }}
                                            >
                                                <li>
                                                    <a 
                                                        href={ExternalLinks.demo_online} 
                                                        title='Demo now'
                                                        target='_blank'>Online limited</a>
                                                </li>
                                                <li>
                                                    <a 
                                                        href={ExternalLinks.demo_booking} 
                                                        title='Schedule a free demo session with one of our agents'
                                                        target='_blank'>Book a full demo</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li
                                            onClick={(e) => handler(e, 'docs')}
                                            id="docs"
                                            className="active"
                                        >
                                            <a href={ExternalLinks.documentation_root}>
                                                Docs <i className="fal fa-link" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="offcanvas-social">
                                    <ul className="text-center">
                                        <li>
                                            <a href={ExternalLinks.socialmedias.facebook} target='_blank'>
                                                <i className="fab fa-facebook-f"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={ExternalLinks.socialmedias.twitter} target='_blank'>
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={ExternalLinks.socialmedias.youtube} target='_blank'>
                                                <i className="fab fa-youtube"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={ExternalLinks.socialmedias.linkedIn} target='_blank'>
                                                <i className="fab fa-linkedin-in"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="footer-widget-info">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-envelope"></i>{' '}
                                                contact@kamvusot.com
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#">
                                                <i className="fal fa-phone"></i> +(642) 342
                                                762 44
                                            </a>
                                        </li> */}
                                        <li>
                                            <a href="#">
                                                <i className="fal fa-map-marker-alt"></i>{' '}
                                                Carrefour des immeubles, Kotto, Douala
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Drawer;
