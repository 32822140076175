import React from 'react';
import heroThumb from '../assets/images/responsive-pharma.png';
import shapeTen from '../assets/images/shape/shape-10.png';
import shapeEleven from '../assets/images/shape/shape-11.png';
import shapeTwelve from '../assets/images/shape/shape-12.png';
import shapeNine from '../assets/images/shape/shape-9.png';
import { Link } from 'react-router-dom';
import ExternalLinks from '../Lib/ExternalLinks';

function Hero() {
    return (
        <>
            <section className="appie-hero-area-2 mb-90">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="appie-hero-content-2">
                                <span>It's free !</span>
                                <h1 className="appie-title">
                                    Take it to the next level, with <span>XPharma</span>
                                </h1>
                                <p>A secured platform for hospital pharmacy management.</p>
                            </div>
                            <div className="appie-hero-content text-left">
                                <ul>
                                    <li>
                                        <Link to="/download">
                                            <i className="fal fa-download" /> Download free
                                        </Link>
                                    </li>
                                    <li>
                                        <a className="item-2" href={ExternalLinks.demo_booking}>
                                            <i className="fal fa-calendar" /> Book a Demo
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="appie-hero-thumb-3 wow animated fadeInRight"
                    data-wow-duration="2000ms"
                    data-wow-delay="400ms"
                >
                    <img src={heroThumb} alt="" />
                </div>
                <div className="hero-shape-1">
                    <img src={shapeNine} alt="" />
                </div>
                <div className="hero-shape-2">
                    <img src={shapeTen} alt="" />
                </div>
                <div className="hero-shape-3">
                    <img src={shapeEleven} alt="" />
                </div>
                <div className="hero-shape-4">
                    <img src={shapeTwelve} alt="" />
                </div>
            </section>
        </>
    );
}

export default Hero;
