import React from 'react';
import useToggle, { CardAction } from '../../Hooks/useToggle';
import Drawer from '../../Components/Drawer';
import Header from '../../Components/Header';
import Hero from '../../Components/Hero';
import Features from '../../Components/Features';
import ChoosingUs from '../../Components/ChoosingUs';
import Footer from '../../Components/Footer';
import Pricing from '../../Components/Pricing';
import CallToAction from '../../Components/CallToAction';
import VideoPlayer from '../../Components/VideoPlayer';
import BackToTop from '../../Components/BackToTop';

function Home() {
  const [drawer, drawerAction] = useToggle(false);
    
  const toggleDrawer = drawerAction as CardAction;

  return (
    <>
      <Drawer drawer={drawer} action={toggleDrawer.toggle} />
      <Header action={toggleDrawer.toggle} />
      <Hero />
      <Features className={undefined} />
      <ChoosingUs style={undefined} />
      <VideoPlayer className="appie-about-8-area pt-5" />
      <Pricing />
      <CallToAction className={undefined} />
      <Footer className={undefined} />
      <BackToTop className="back-to-top-2" />
    </>
  );
}

export default Home;
