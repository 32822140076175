import React from 'react';
import ExternalLinks from '../Lib/ExternalLinks';

function Features({ className }) {
    return (
        <>
            <section className={`appie-services-2-area pb-100 ${className}`} id="service">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-6 col-md-8">
                            <div className="appie-section-title">
                                <h3 className="appie-title">What it can do</h3>
                                <p>
                                    A complete tool set for hospital pharmacy management.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-4">
                            <div className="appie-section-title text-right">
                                <a className="main-btn" href={ExternalLinks.documentation_root}>
                                    View all Features <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-bed" />
                                </div>
                                <h4 className="title">Patient management system</h4>
                                <p>
                                    Easily record and retrieve your hospital's patient information. 
                                    Record visits to track all patient activity and check patient 
                                    creditworthiness on discharge in one click.
                                </p>
                                <a href={ExternalLinks.documentation_root}>
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-pills" />
                                </div>
                                <h4 className="title">Inventories and medicines stock control</h4>
                                <p>
                                    Inventories are made simple and efficient.
                                    View your stock levels in real time, define critical quantities 
                                    and receive alerts in the event of significant stock reductions 
                                    or shortages. You can also receive alerts when your 
                                    medicines are about to expire. 
                                </p>
                                <a href={ExternalLinks.documentation_root}>
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-shopping-basket"></i>
                                </div>
                                <h4 className="title">Orders and requisitions</h4>
                                <p>
                                    Easily prepare orders for suppliers and automatically 
                                    update stocks on delivery. Manage differents stocks per ward
                                    independently and use requisitions to request and transfer
                                    medicines from ward to ward.
                                </p>
                                <a href={ExternalLinks.documentation_root}>
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-briefcase" />
                                </div>
                                <h4 className="title">Services management</h4>
                                <p>
                                    XPharma takes into account all the other services your 
                                    hospital provides, including surgical procedures and 
                                    exams. This means you can centralise the monitoring 
                                    of your revenue in a single application.
                                </p>
                                <a href={ExternalLinks.documentation_root}>
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="400ms"
                            >
                                <div className="icon">
                                    <i className="fas fa-file-invoice" />
                                </div>
                                <h4 className="title">Efficient Invoice System</h4>
                                <p>
                                Prepare invoices quickly and easily, then validate them when 
                                you're ready. Collect payments flexibly and securely. 
                                Define patient discount plans and make your life easier! 
                                Do you have a product reimbursement plan in place for your patients? 
                                Do it securely and easily.
                                </p>
                                <a href={ExternalLinks.documentation_root}>
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div
                                className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <i className="far fa-chart-bar"></i>
                                </div>
                                <h4 className="title">Efficient reporting function</h4>
                                <p>
                                    The reports help you to understand the current situation of your 
                                    hospital pharmacy and to make decisions for the future. 
                                    XPharma allows you to preview reports graphically and in 
                                    detail, and export them to a variety of file formats.
                                </p>
                                <a href={ExternalLinks.documentation_root}>
                                    Read More <i className="fal fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Features;
